<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <el-form
              ref="ruleFormRef"
              :model="form"
              status-icon
              @submit.prevent="submitForm"
            >
              <div class="col-lg-4 col-md-6 ml-auto mr-auto">
                <el-card type="login">
                  <h3 class="header text-center">Đăng nhập</h3>
                  <el-form-item
                    class="mb-4"
                    prop="email"
                    :rules="[
                      {
                        required: true,
                        message: 'Vui lòng nhập email',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      v-model="form.email"
                      type="email"
                      placeholder="Email..."
                      size="large"
                      @keyup.enter="submitForm"
                    />

                  </el-form-item>
                  <el-form-item
                    class="mb-4"
                    prop="password"
                    :rules="[
                      {
                        required: true,
                        message: 'Vui lòng nhập mật khẩu',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      v-model="form.password"
                      placeholder="Mật khẩu"
                      type="password"
                      size="large"
                      @keyup.enter="submitForm"
                    />
                  </el-form-item>

                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onRecaptchaVerify"
                    sitekey="6LfuQuoUAAAAAKCuXGlkoTO0dJQJH8V55OeUSHLr"
                  ></vue-recaptcha>
                  <el-button type="primary" size="large" round class="w-100 my-3" native-type="submit">Đăng nhập</el-button>
                </el-card>
              </div>
            </el-form>
          </div>
        </div>
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/background-2.jpg)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import {ElCard ,ElInput, ElForm, ElFormItem, ElButton,ElMessage} from "element-plus";
import { VueRecaptcha } from "vue-recaptcha";


export default {
  components: {
    ElForm, ElFormItem,
    ElInput,
    ElButton,
    ElCard,
    VueRecaptcha: VueRecaptcha,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        captcha: "",
      },
    };
  },
  methods: {
    onRecaptchaVerify(response) {
      this.form.captcha = response;
    },

    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    submitForm() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {

          if (location.hostname =='dashboard.irace.vn' && !this.form.captcha) {
            return ElMessage({
                message: "Vui lòng chọn tôi không phải là người máy",
                type: "error",
            });
          };
          this.$store.dispatch('toggleLoading');
          let self = this;
          let data = {};
          data.email = self.form.email;
          data.password = self.form.password;
          data["g-recaptcha-response"] = self.form.captcha;
          self.$store.dispatch("authLogin", data).then(
            (res) => {
              if (!res.code) {
                this.$store.dispatch('toggleLoading');
                ElMessage({
                  message: "Đăng nhập thành công",
                  type: "success",
                });
                localStorage.setItem("login_time", new Date().getTime());
                const redirect_url = localStorage.getItem("redirect_url");
                if (redirect_url) {
                  localStorage.removeItem("redirect_url");
                  location.href = redirect_url;
                  return;
                }
                const role = self.$store.state.role;
                if (role == 2) {
                  self.$router.push("/races");
                } else if (role == 3) {
                  self.$router.push("/orders");
                } else if (role == 4) {
                  self.$router.push("/bills");
                } else {
                  self.$router.push("/admin/overview");
                }
              } else {
                this.resetForm();
                ElMessage({
                  message: res.message,
                  type: "error",
                });
              }
            },
            (error) => {
              this.resetForm();
              ElMessage({
                message: error,
                type: "error",
              });
            }
          );
        }
      })
    },
    resetForm() {
      this.$store.dispatch('toggleLoading');
      this.$refs.recaptcha.reset();
      this.form.captcha = null;
    },
  },
  
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
