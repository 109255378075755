import axios from './../api';
import { buildQuery } from "../actions";
export default {
    namespaced: true,

    state: {
        themes: [],
        theme_detail: {}
    },

    mutations: {
        THEMES(state, val) {
            state.themes = val;
        },

        THEME_DETAIL(state, val) {
            state.theme_detail = val;
        },

        THEME_VARS(state, val) {
            state.theme_vars = val;
        }
    },

    getters: {
        vip_themes: state => state.themes,
        vip_theme_detail: state => state.theme_detail,
        vip_theme_vars: state=>state.theme_vars,
    },

    actions: {

      fetchThemes(ctx) {
        let { commit, state } = ctx;
        axios
            .get("/api/admin/v1/vip-themes")
            .then((response) => {
                commit("THEMES", response.data.data);
            })
            .catch((error) => {
                console.error("fetchGifts: ", error);
            });
    },

        detail(ctx, id) {
            let {commit, state} = ctx;
            axios.get('/api/admin/v1/vip-themes/' + id)
            .then(response => {
                commit('THEME_DETAIL', response.data.data);
            })
            .catch(error => {
                console.error('fetchTheme: ', error);
            });
        },

        store(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/v1/vip-themes`, obj)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        },


        async fetchVars(ctx) {
          let { commit, state } = ctx;
          axios
              .get("/api/admin/v1/vip-themes/variables")
              .then((response) => {
                  commit("THEME_VARS", response.data.data);
              })
              .catch((error) => {
                  console.error("fetchReportFields: ", error);
              });
        },

        update(ctx, obj) {
          console.log(obj)
            return new Promise((resolve, reject) => {
                axios
                .post(`/api/admin/v1/vip-themes/${obj.get("id")}` , obj)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                });
        },

        destroy(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/admin/v1/vip-themes/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        }

    },
}

