import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/store"; // your vuex store

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

import DashboardLayout from "../components/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "../components/GeneralViews/NotFoundPage.vue";
// Dashboard pages
const Overview = () =>
  import("src/components/Dashboard/Views/Dashboard/Overview.vue");
const Widgets = () =>
  import("src/components/Dashboard/Views/Dashboard/Widgets.vue");

import Login from "src/components/Dashboard/Views/Sample-Pages/Login.vue";

// Components pages
import Buttons from "src/components/Dashboard/Views/Sample-Components/Buttons.vue";
import GridSystem from "src/components/Dashboard/Views/Sample-Components/GridSystem.vue";
import Panels from "src/components/Dashboard/Views/Sample-Components/Panels.vue";
import SweetAlert from "src/components/Dashboard/Views/Sample-Components/SweetAlert.vue";
import Notifications from "src/components/Dashboard/Views/Sample-Components/Notifications.vue";
import Icons from "src/components/Dashboard/Views/Sample-Components/Icons.vue";
import Typography from "src/components/Dashboard/Views/Sample-Components/Typography.vue";

// Forms pages
const RegularForms = () =>
  import("src/components/Dashboard/Views/Sample-Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/components/Dashboard/Views/Sample-Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/components/Dashboard/Views/Sample-Forms/ValidationForms.vue");
const Wizard = () =>
  import("src/components/Dashboard/Views/Sample-Forms/Wizard.vue");

// TableList pages
const RegularTables = () =>
  import("src/components/Dashboard/Views/Sample-Tables/RegularTables.vue");
const ExtendedTables = () =>
  import("src/components/Dashboard/Views/Sample-Tables/ExtendedTables.vue");
const PaginatedTables = () =>
  import("src/components/Dashboard/Views/Sample-Tables/PaginatedTables.vue");

// Report pages
const AllReport = () =>
  import("src/components/Dashboard/Views/Report/All-Report.vue");

// Orders pages
const AllOrders = () =>
  import("src/components/Dashboard/Views/Orders/All-Orders.vue");
const EditOrder = () =>
  import("src/components/Dashboard/Views/Orders/Edit-Order.vue");

// Tickets pages
const AllTickets = () => import("src/components/Dashboard/Views/Ticket/All-Tickets.vue");
const EditTicket = () => import("src/components/Dashboard/Views/Ticket/Edit-Ticket.vue");

const ImportTicket = () => import("src/components/Dashboard/Views/Ticket/Import-Ticket.vue");

// Setting pages
const AllCaching = () =>
  import("src/components/Dashboard/Views/Settings/All-Caching.vue");
const AllLogs = () =>
  import("src/components/Dashboard/Views/Logs/All-Logs.vue");
const Cheat = () =>
  import("src/components/Dashboard/Views/Cheat/All-Cheat.vue");
const Css = () => import("src/components/Dashboard/Views/Theme/Css.vue");

const SupportTool = () =>
  import("src/components/Dashboard/Views/Settings/SupportTool.vue");

const AIO = () => import("src/components/Dashboard/Views/Settings/AIO.vue");

// User pages
const CreateUser = () =>
  import("src/components/Dashboard/Views/Settings/Create-User.vue");

// Email page
const Email = () => import("src/components/Dashboard/Views/Settings/Email.vue");
const History = () =>
  import("src/components/Dashboard/Views/Settings/History.vue");
const Shipping = () =>
  import("src/components/Dashboard/Views/Settings/Shipping.vue");
const Payment = () =>
  import("src/components/Dashboard/Views/Settings/Payment.vue");

// role
const Role = () => import("src/components/Dashboard/Views/Settings/Role.vue");
const CreateRole = () =>
  import("src/components/Dashboard/Views/Settings/Create-Role.vue");
const Deleted = () =>
  import("src/components/Dashboard/Views/Settings/Deleted.vue");

// Users
const AllUsers = () =>
  import("src/components/Dashboard/Views/Users/All-Users.vue");
const EditUser = () =>
  import("src/components/Dashboard/Views/Users/Edit-User.vue");

// Charities
const AllCharities = () =>
  import("src/components/Dashboard/Views/Charities/All-Charities.vue");
const CreateCharity = () =>
  import("src/components/Dashboard/Views/Charities/Create-Charity.vue");
const EditCharity = () =>
  import("src/components/Dashboard/Views/Charities/Edit-Charity.vue");

// Races
const AllRaces = () =>
  import("src/components/Dashboard/Views/Races/All-Races.vue");
const CreateRace = () =>
  import("src/components/Dashboard/Views/Races/Create-Race.vue");
const EditRace = () =>
  import("src/components/Dashboard/Views/Races/Edit-Race.vue");

//addon
const AllAddons = () =>
  import("src/components/Dashboard/Views/Addon/All-Addons.vue");
const CreateAddon = () =>
  import("src/components/Dashboard/Views/Addon/Create-Addon.vue");
const EditAddon = () =>
  import("src/components/Dashboard/Views/Addon/Edit-Addon.vue");
const AllCategories = () =>
  import("src/components/Dashboard/Views/ProductCategories/All-Categories.vue");
const CreateCategory = () =>
  import(
    "src/components/Dashboard/Views/ProductCategories/Create-Category.vue"
  );
const EditCategory = () =>
  import("src/components/Dashboard/Views/ProductCategories/Edit-Category.vue");
const OrderProduct = () =>
  import("src/components/Dashboard/Views/Addon/All-Orders.vue");
const OrderProductDetail = () =>
  import("src/components/Dashboard/Views/Addon/Order-Detail.vue");

// Exercise
const AllExercises = () =>
  import("src/components/Dashboard/Views/Exercise/All-Exercises.vue");
const CreateExercise = () =>
  import("src/components/Dashboard/Views/Exercise/Create-Exercise.vue");
const EditExercise = () =>
  import("src/components/Dashboard/Views/Exercise/Edit-Exercise.vue");

//catalogs
const AllCatalogs = () =>
  import("src/components/Dashboard/Views/Catalogs/All-Catalog.vue");
const CreateCatalog = () =>
  import("src/components/Dashboard/Views/Catalogs/Create-Catalog.vue");
const EditCatalog = () =>
  import("src/components/Dashboard/Views/Catalogs/Edit-Catalog.vue");

//rule
const AllRules = () =>
  import("src/components/Dashboard/Views/Rule/All-Rule.vue");
const CreateRule = () =>
  import("src/components/Dashboard/Views/Rule/Create-Rule.vue");
const EditRule = () =>
  import("src/components/Dashboard/Views/Rule/Edit-Rule.vue");

/* iVip */
const AllVip = () => import("src/components/Dashboard/Views/Vip/All-Vip.vue");
const CreateVip = () => import("src/components/Dashboard/Views/Vip/Create-Vip.vue");
const EditVip = () => import("src/components/Dashboard/Views/Vip/Edit-Vip.vue");

const OrderVip = () =>
  import("src/components/Dashboard/Views/Vip/All-Orders.vue");
const EditOrderVip = () =>
  import("src/components/Dashboard/Views/Vip/Edit-Order.vue");


/* FAQS*/
const AllFaqs = () => import("src/components/Dashboard/Views/Faq/All-Faq.vue");
const EditFaqs = () => import("src/components/Dashboard/Views/Faq/Edit-Faq.vue");

/*TESTIMONIALs*/
const AllTestimonials = () => import("src/components/Dashboard/Views/Testimonials/All-Testimonial.vue");
const EditTestimonials = () => import("src/components/Dashboard/Views/Testimonials/Edit-Testimonial.vue");


/*Theme*/
const AllVipThemes = () => import("src/components/Dashboard/Views/iVip/All-Theme.vue");
const EditVipThemes = () => import("src/components/Dashboard/Views/iVip/Edit-Theme.vue");

/* Promotion */
const AllPromotions = () =>
  import("src/components/Dashboard/Views/Promotion/All-Promotion.vue");
const CreatePromotion = () =>
  import("src/components/Dashboard/Views/Promotion/Create-Promotion.vue");
const EditPromotion = () =>
  import("src/components/Dashboard/Views/Promotion/Edit-Promotion.vue");

/* Operation & Ship */
const OperationShip = () =>
  import("src/components/Dashboard/Views/Operation/Shipping.vue");

//themes
const AllThemes = () =>
  import("src/components/Dashboard/Views/ThemeRace/All-Theme.vue");
const CreateTheme = () =>
  import("src/components/Dashboard/Views/ThemeRace/Create-Theme.vue");
const EditTheme = () =>
  import("src/components/Dashboard/Views/ThemeRace/Edit-Theme.vue");

//race type
const AllTypeRaces = () =>
  import("src/components/Dashboard/Views/TypeRace/All-Type.vue");
const CreateTypeRace = () =>
  import("src/components/Dashboard/Views/TypeRace/Create-Type.vue");
const EditTypeRace = () =>
  import("src/components/Dashboard/Views/TypeRace/Edit-Type.vue");

//race cate
const AllCateRaces = () =>
  import("src/components/Dashboard/Views/CategoryRace/All-Cate.vue");
const CreateCateRace = () =>
  import("src/components/Dashboard/Views/CategoryRace/Create-Cate.vue");
const EditCateRace = () =>
  import("src/components/Dashboard/Views/CategoryRace/Edit-Cate.vue");

//notification
const AllNotifications = () =>
  import("src/components/Dashboard/Views/Notification/All-Notification.vue");
const CreateNotifications = () =>
  import("src/components/Dashboard/Views/Notification/Create-Notification.vue");

//coupon
const AllCoupons = () =>
  import("src/components/Dashboard/Views/Coupon/All-Coupon.vue");
const CreateCoupon = () =>
  import("src/components/Dashboard/Views/Coupon/Create-Coupon.vue");
const EditCoupon = () =>
  import("src/components/Dashboard/Views/Coupon/Edit-Coupon.vue");
const SearchCoupons = () =>
  import("src/components/Dashboard/Views/Coupon/Search-Coupon.vue");

// donate
const AllDonates = () =>
  import("src/components/Dashboard/Views/Donate/All-Donate.vue");

//club
const AllClubs = () =>
  import("src/components/Dashboard/Views/Clubs/All-Club.vue");
const CreateClub = () =>
  import("src/components/Dashboard/Views/Clubs/Create-Club.vue");
const EditClub = () =>
  import("src/components/Dashboard/Views/Clubs/Edit-Club.vue");

//location
const AllLocations = () =>
  import("src/components/Dashboard/Views/Locations/All-Location.vue");
const CreateLocation = () =>
  import("src/components/Dashboard/Views/Locations/Create-Location.vue");
const EditLocation = () =>
  import("src/components/Dashboard/Views/Locations/Edit-Location.vue");

/* Company */
const AllCompanies = () =>
  import("src/components/Dashboard/Views/Companies/All-Company.vue");
const CreateCompany = () =>
  import("src/components/Dashboard/Views/Companies/Create-Company.vue");
const EditCompany = () =>
  import("src/components/Dashboard/Views/Companies/Edit-Company.vue");

/* Group */
const AllGroup = () =>
  import("src/components/Dashboard/Views/Group/All-Group.vue");
const CreateGroup = () =>
  import("src/components/Dashboard/Views/Group/Create-Group.vue");
const EditGroup = () =>
  import("src/components/Dashboard/Views/Group/Edit-Group.vue");

//school
const AllSchools = () =>
  import("src/components/Dashboard/Views/Schools/All-School.vue");
const CreateSchool = () =>
  import("src/components/Dashboard/Views/Schools/Create-School.vue");
const EditSchool = () =>
  import("src/components/Dashboard/Views/Schools/Edit-School.vue");

//Mail Templates
const AllMails = () =>
  import("src/components/Dashboard/Views/Mail/All-Mail.vue");
const CreateMail = () =>
  import("src/components/Dashboard/Views/Mail/Create-Mail.vue");
const EditMail = () =>
  import("src/components/Dashboard/Views/Mail/Edit-Mail.vue");

//Mail Forms
const AllMailForms = () =>
  import("src/components/Dashboard/Views/Mail/All-Form.vue");
const CreateMailForm = () =>
  import("src/components/Dashboard/Views/Mail/Create-Form.vue");
const EditMailForm = () =>
  import("src/components/Dashboard/Views/Mail/Edit-Form.vue");
const SendMailStatus = () =>
  import("src/components/Dashboard/Views/Mail/Status.vue");
const MailMerge = () =>
  import("src/components/Dashboard/Views/Mail/MailMerge.vue");

//admin
const AllAdmins = () =>
  import("src/components/Dashboard/Views/Admin/All-Admin.vue");
const CreateAdmin = () =>
  import("src/components/Dashboard/Views/Admin/Create-Admin.vue");
const EditAdmin = () =>
  import("src/components/Dashboard/Views/Admin/Edit-Admin.vue");

//menu
const AllMenus = () =>
  import("src/components/Dashboard/Views/Menu/All-Menu.vue");
const CreateMenu = () =>
  import("src/components/Dashboard/Views/Menu/Create-Menu.vue");
const EditMenu = () =>
  import("src/components/Dashboard/Views/Menu/Edit-Menu.vue");

// Setting/banner
const AllBanner = () =>
  import("src/components/Dashboard/Views/Settings/All-Banner.vue");
const AllCoin = () =>
  import("src/components/Dashboard/Views/Settings/All-Coin.vue");

// Activities
const Run = () =>
  import("src/components/Dashboard/Views/Activities/All-Run.vue");
const Workout = () =>
  import("src/components/Dashboard/Views/Activities/All-Workout.vue");
const Walk = () =>
  import("src/components/Dashboard/Views/Activities/All-Walk.vue");
const EditRun = () =>
  import("src/components/Dashboard/Views/Activities/Edit-Run.vue");

// Enterprises
const AllPackageEnterprises = () =>
  import("src/components/Dashboard/Views/Enterprise/All-Packages.vue");
const CreatePackageEnterprises = () =>
  import("src/components/Dashboard/Views/Enterprise/Create-Package.vue");
const EditPackageEnterprises = () =>
  import("src/components/Dashboard/Views/Enterprise/Edit-Package.vue");
const AllRacesEnterprises = () =>
  import("src/components/Dashboard/Views/Enterprise/All-Races.vue");
const AllOrdersEnterprises = () =>
  import("src/components/Dashboard/Views/Enterprise/All-Orders.vue");
const EditRaceEnterprises = () =>
  import("src/components/Dashboard/Views/Enterprise/Edit-Race.vue");
const ExportVirtualRace = () =>
  import("src/components/Dashboard/Views/Races/Export-Race.vue");
const ImportVirtualRace = () =>
  import("src/components/Dashboard/Views/Races/Import-Race.vue");
const EditOrderEnterprises = () =>
  import("src/components/Dashboard/Views/Enterprise/Edit-Order.vue");

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem,
    },
    {
      path: "panels",
      name: "Panels",
      component: Panels,
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      component: SweetAlert,
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons,
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography,
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      component: RegularForms,
    },
    {
      path: "extended",
      name: "Extended Forms",
      component: ExtendedForms,
    },
    {
      path: "validation",
      name: "Validation Forms",
      component: ValidationForms,
    },
    {
      path: "wizard",
      name: "Wizard",
      component: Wizard,
    },
  ],
};
let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      component: RegularTables,
    },
    {
      path: "extended",
      name: "Extended Tables",
      component: ExtendedTables,
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables,
    },
  ],
};

let EnterPriseMenu = {
  path: "/enterprise",
  meta: { roles: [1] },
  component: DashboardLayout,
  redirect: "/enterprise/packages",
  children: [
    {
      path: "packages",
      name: "AllPackageEnterprises",
      component: AllPackageEnterprises,
    },
    {
      path: "packages/create",
      name: "CreatePackageEnterprises",
      component: CreatePackageEnterprises,
    },
    {
      path: "packages/:id",
      name: "EditPackageEnterprises",
      component: EditPackageEnterprises,
    },
    {
      path: "races",
      name: "AllRacesEnterprises",
      component: AllRacesEnterprises,
    },
    {
      path: "races/:id",
      name: "EditRaceEnterprises",
      component: EditRaceEnterprises,
    },

    {
      path: "orders",
      name: "AllOrdersEnterprises",
      component: AllOrdersEnterprises,
    },
    {
      path: "orders/:id",
      name: "EditOrderEnterprises",
      component: EditOrderEnterprises,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let ordersMenu = {
  path: "/orders",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllOrders",
      component: AllOrders,
    },
    {
      path: ":id",
      name: "EditOrder",
      component: EditOrder,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let reportMenu = {
  path: "/reports",
  component: DashboardLayout,
  meta: { roles: [1] },
  children: [
    {
      path: "",
      name: "AllReport",
      component: AllReport,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let ticketsMenu = {
  path: "/tickets",
  meta: { roles: [1, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllTickets",
      component: AllTickets,
    },
    {
        path: "import",
        name: "ImportTicket",
        component: ImportTicket,
      },
    {
      path: ":id",
      name: "EditTicket",
      component: EditTicket,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let cssMenu = {
  path: "/css",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "CSS",
      component: Css,
    },
  ],
};

let AIOMenu = {
  path: "/aio",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AIO",
      component: AIO,
    },
  ],
};

let supportToolMenu = {
  path: "/support-tool",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "SupportTool",
      component: SupportTool,
    },
  ],
};

let cachingMenu = {
  path: "/caching",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Caching",
      component: AllCaching,
    },
  ],
};

let logsMenu = {
  path: "/logs",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "logs",
      component: AllLogs,
    },
  ],
};

let usersMenu = {
  path: "/users",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "User",
      component: AllUsers,
    },
    {
      path: "create",
      name: "CreateUser",
      component: CreateUser,
    },
    {
      path: ":id",
      name: "EditUser",
      component: EditUser,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let charitiesMenu = {
  path: "/charities",
  meta: { roles: [1, 2] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Charity",
      component: AllCharities,
    },
    {
      path: "create",
      name: "CreateCharity",
      component: CreateCharity,
    },
    {
      path: ":id",
      name: "EditCharity",
      component: EditCharity,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let racesMenu = {
  path: "/races",
  meta: { roles: [1, 2] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllRace",
      component: AllRaces,
    },
    {
      path: "create",
      name: "CreateRace",
      component: CreateRace,
    },
    {
      path: "export-data",
      name: "ExportVirtualRace",
      component: ExportVirtualRace,
    },
    {
      path: "import-data",
      name: "ImportVirtualRace",
      component: ImportVirtualRace,
    },
    {
      path: ":id",
      name: "EditRace",
      component: EditRace,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let emailMenu = {
  path: "/email",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Thiết lập email",
      component: Email,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let historyMenu = {
  path: "/history",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Lịch sử hoạt động",
      component: History,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let cheatMenu = {
  path: "/cheats",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Gian lận",
      component: Cheat,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let activityMenu = {
  path: "/activities",
  meta: { roles: [1] },
  component: DashboardLayout,
  redirect: "/activities/run",
  children: [
    {
      path: "run",
      name: "Run",
      component: Run,
    },
    {
      path: "run/:id",
      name: "EditRun",
      component: EditRun,
    },
    {
      path: "workout",
      name: "Workout",
      component: Workout,
    },
    {
      path: "walk",
      name: "Walk",
      component: Walk,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let shippingMenu = {
  path: "/shipping",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Phí vận chuyển",
      component: Shipping,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let paymentMenu = {
  path: "/payment",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Thanh toán",
      component: Payment,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let roleMenu = {
  path: "/role",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Role",
      component: Role,
    },
    {
      path: "create",
      name: "CreateRole",
      component: CreateRole,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let deletedMenu = {
  path: "/deleted",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "Dữ liệu đã xóa",
      component: Deleted,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let addonsMenu = {
  path: "/addons",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllAddons",
      component: AllAddons,
    },
    {
      path: "create",
      name: "CreateAddon",
      component: CreateAddon,
    },
    {
      path: ":id",
      name: "EditAddon",
      component: EditAddon,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let productCategoryMenu = {
  path: "/product-categories",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllCategories",
      component: AllCategories,
    },
    {
      path: "create",
      name: "CreateCategory",
      component: CreateCategory,
    },
    {
      path: ":id",
      name: "EditCategory",
      component: EditCategory,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let exerciseMenu = {
  path: "/exercises",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllExercises",
      component: AllExercises,
    },
    {
      path: "create",
      name: "CreateExercise",
      component: CreateExercise,
    },
    {
      path: ":id",
      name: "EditExercise",
      component: EditExercise,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let orderProductMenu = {
  path: "/order-products",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "OrderProduct",
      component: OrderProduct,
    },
    {
      path: ":id",
      name: "OrderProductDetail",
      component: OrderProductDetail,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let orderVipMenu = {
  path: "/order-vips",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "OrderVip",
      component: OrderVip,
    },
    {
      path: ":id",
      name: "EditOrderVip",
      component: EditOrderVip,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let catalogsMenu = {
  path: "/catalogs",
  meta: { roles: [1, 2] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllCatalogs",
      component: AllCatalogs,
    },
    {
      path: "create",
      name: "CreateCatalog",
      component: CreateCatalog,
    },
    {
      path: ":id",
      name: "EditCatalog",
      component: EditCatalog,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let rulesMenu = {
  path: "/rules",
  meta: { roles: [1, 2] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllRules",
      component: AllRules,
    },
    {
      path: "create",
      name: "CreateRule",
      component: CreateRule,
    },
    {
      path: ":id",
      name: "EditRule",
      component: EditRule,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let vipMenu = {
  path: "/vip",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllVip",
      component: AllVip,
    },
    {
      path: "create",
      name: "CreateVip",
      component: CreateVip,
    },
    {
      path: ":id",
      name: "EditVip",
      component: EditVip,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let themeVipMenu = {
  path: "/vip/themes",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllVipThemes",
      component: AllVipThemes,
    },
    {
      path: ":id",
      name: "EditVipThemes",
      component: EditVipThemes,
    },
  ],
  beforeEnter: ifAuthenticated,
}
let faqMenu = {
  path: "/faqs",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllFaqs",
      component: AllFaqs,
    },
    {
      path: ":id",
      name: "EditFaqs",
      component: EditFaqs,
    },
  ],
  beforeEnter: ifAuthenticated,
};


let testimonialMenu = {
  path: "/testimonials",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllTestimonials",
      component: AllTestimonials,
    },
    {
      path: ":id",
      name: "EditTestimonials",
      component: EditTestimonials,
    },
  ],
  beforeEnter: ifAuthenticated,
};


let promotionMenu = {
  path: "/promotions",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllPromotions",
      component: AllPromotions,
    },
    {
      path: "create",
      name: "CreatePromotion",
      component: CreatePromotion,
    },
    {
      path: ":id",
      name: "EditPromotion",
      component: EditPromotion,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let operationShipMenu = {
  path: "/operation-ship",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "OperationShip",
      component: OperationShip,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let themesMenu = {
  path: "/themes",
  meta: { roles: [1, 2] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllThemes",
      component: AllThemes,
    },
    {
      path: "create",
      name: "CreateTheme",
      component: CreateTheme,
    },
    {
      path: ":id",
      name: "EditTheme",
      component: EditTheme,
    },
  ],
};

let typeRacesMenu = {
  path: "/race-type",
  meta: { roles: [1, 2] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllTypeRaces",
      component: AllTypeRaces,
    },
    {
      path: "create",
      name: "CreateTypeRace",
      component: CreateTypeRace,
    },
    {
      path: ":id",
      name: "EditTypeRace",
      component: EditTypeRace,
    },
  ],
};

let categoryRaceMenu = {
  path: "/category-race",
  meta: { roles: [1, 2] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllCateRaces",
      component: AllCateRaces,
    },
    {
      path: "create",
      name: "CreateCateRace",
      component: CreateCateRace,
    },
    {
      path: ":id",
      name: "EditCateRace",
      component: EditCateRace,
    },
  ],
};

let notificationsMenu = {
  path: "/notifications",
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllNotifications",
      component: AllNotifications,
    },
    {
      path: "create",
      name: "CreateNotifications",
      component: CreateNotifications,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let couponsMenu = {
  path: "/coupons",
  meta: { roles: [1, 3] },
  redirect: "/coupons/all",
  component: DashboardLayout,
  children: [
    {
      path: "all",
      name: "AllCoupons",
      component: AllCoupons,
    },
    {
      path: "create",
      name: "CreateCoupon",
      component: CreateCoupon,
    },
    {
      path: "search",
      name: "SearchCoupon",
      component: SearchCoupons,
    },
    {
      path: ":id",
      name: "EditCoupon",
      component: EditCoupon,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let donatesMenu = {
  path: "/donates",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllDonates",
      component: AllDonates,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let clubsMenu = {
  path: "/clubs",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllClubs",
      component: AllClubs,
    },
    {
      path: "create",
      name: "CreateClub",
      component: CreateClub,
    },
    {
      path: ":id",
      name: "EditClub",
      component: EditClub,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let locationsMenu = {
  path: "/locations",
  meta: { roles: [1, 2] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllLocations",
      component: AllLocations,
    },
    {
      path: "create",
      name: "CreateLocation",
      component: CreateLocation,
    },
    {
      path: ":id",
      name: "EditLocation",
      component: EditLocation,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let companiesMenu = {
  path: "/companies",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllCompanies",
      component: AllCompanies,
    },
    {
      path: "create",
      name: "CreateCompany",
      component: CreateCompany,
    },
    {
      path: ":id",
      name: "EditCompany",
      component: EditCompany,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let groupMenu = {
  path: "/groups",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllGroup",
      component: AllGroup,
    },
    {
      path: "create",
      name: "CreateGroup",
      component: CreateGroup,
    },
    {
      path: ":id",
      name: "EditGroup",
      component: EditGroup,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let schoolsMenu = {
  path: "/schools",
  meta: { roles: [1, 3, 4] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllSchools",
      component: AllSchools,
    },
    {
      path: "create",
      name: "CreateSchool",
      component: CreateSchool,
    },
    {
      path: ":id",
      name: "EditSchool",
      component: EditSchool,
    },
  ],
  beforeEnter: ifAuthenticated,
};

let mailsMenu = {
  path: "/mail/templates",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllMails",
      component: AllMails,
    },
    {
      path: "create",
      name: "CreateMail",
      component: CreateMail,
    },
    {
      path: ":id",
      name: "EditMail",
      component: EditMail,
    },
  ],
};

let mailFormsMenu = {
  path: "/mail/forms",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllMailForms",
      component: AllMailForms,
    },
    {
      path: "create",
      name: "CreateMailForm",
      component: CreateMailForm,
    },
    {
      path: ":id",
      name: "EditMailForm",
      component: EditMailForm,
    },
  ],
};

let sendMailStatus = {
  path: "/mail/status",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "allsendMailStatus",
      component: SendMailStatus,
    },
  ],
};

let mailMergeMenu = {
  path: "/mail-merge",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "MailMerge",
      component: MailMerge,
    },
  ],
};

let adminsMenu = {
  path: "/admins",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllAdmins",
      component: AllAdmins,
    },
    {
      path: "create",
      name: "CreateAdmin",
      component: CreateAdmin,
    },
    {
      path: ":id",
      name: "EditAdmin",
      component: EditAdmin,
    },
  ],
};

let menusMenu = {
  path: "/menus",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllMenus",
      component: AllMenus,
    },
    {
      path: "create",
      name: "CreateMenu",
      component: CreateMenu,
    },
    {
      path: ":id",
      name: "EditMenu",
      component: EditMenu,
    },
  ],
};

let bannerMenu = {
  path: "/setting-banner",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllBanner",
      component: AllBanner,
    },
  ],
};

let coinMenu = {
  path: "/icoin",
  meta: { roles: [1] },
  component: DashboardLayout,
  children: [
    {
      path: "",
      name: "AllCoin",
      component: AllCoin,
    },
  ],
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
};

import event from "./event.js";
import attribute from "./attribute.js";
import event_categories from "./event-categories.js";
import event_addons from "./event-addons.js";
import affiliate from "./affiliate";
import mail from "./mail";
import media from "./media.js";
import banner_ads from "./banner-ads.js";
import ivip from "./ivip.js";
import sponsor from "./sponsor.js";
import faq from "./faq.js";
import default_email_template from "./default_email_template.js";
import oauth from "./oauth.js";
import testimonial from "./testimonial.js";

const requireModule = require.context('./modules', false, /\.js$/);
const routeModules = [];

requireModule.keys().forEach((fileName) => {
    const module = requireModule(fileName);
    routeModules.push(...(module.default || module));
});


const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/admin/overview",
    name: "dashboard",
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  loginPage,
  ordersMenu,
  reportMenu,
  usersMenu,
  charitiesMenu,
  orderProductMenu,
  exerciseMenu,
  productCategoryMenu,
  orderVipMenu,
  racesMenu,
  menusMenu,
  cssMenu,
  ticketsMenu,
  AIOMenu,
  supportToolMenu,
  cachingMenu,
  logsMenu,
  emailMenu,
  historyMenu,
  cheatMenu,
  shippingMenu,
  paymentMenu,
  roleMenu,
  deletedMenu,
  addonsMenu,
  catalogsMenu,
  vipMenu,
  themeVipMenu,
  rulesMenu,
  themesMenu,
  promotionMenu,
  faqMenu,
  testimonialMenu,
  operationShipMenu,
  typeRacesMenu,
  categoryRaceMenu,
  notificationsMenu,
  couponsMenu,
  clubsMenu,
  locationsMenu,
  bannerMenu,
  coinMenu,
  companiesMenu,
  schoolsMenu,
  groupMenu,
  mailsMenu,
  mailFormsMenu,
  activityMenu,
  mailMergeMenu,
  adminsMenu,
  sendMailStatus,
  EnterPriseMenu,
  donatesMenu,
  {
    path: "/admin",
    component: DashboardLayout,
    redirect: "/admin/overview",
    children: [
      {
        path: "overview",
        name: "Overview",
        component: Overview,
      },
      {
        path: "widgets",
        name: "Widgets",
        component: Widgets,
      },
    ],
  },
  ...event,
  ...attribute,
  ...event_categories,
  ...event_addons,
  ...affiliate,
  ...mail,
  ...media,
  ...banner_ads,
  ...ivip,
  ...testimonial,
  ...sponsor,
  ...faq,
  ...default_email_template,
  ...oauth,
  ...routeModules,
  { path: "/:catchAll(.*)", component: NotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, _, next) => {
  try {
    if(to.meta.roles) {
      if(to.meta.roles.includes(+store.state.role)) return next();
      next('/404');
    }else {
      next();
    }
  } catch (error) {
    next('/login')
  }
});

export default router;
